/**
 * @generated SignedSource<<9edf095166e2286614c1dd5faaa4e84b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountPermissionDomainName = "ACCOUNT_PERMISSION_DOMAIN_NAME_ANALYTICS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_APPLICATIONS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_BRAND_KIT" | "ACCOUNT_PERMISSION_DOMAIN_NAME_EMAIL" | "ACCOUNT_PERMISSION_DOMAIN_NAME_GROWTH" | "ACCOUNT_PERMISSION_DOMAIN_NAME_MESSAGING" | "ACCOUNT_PERMISSION_DOMAIN_NAME_OFFERS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SEGMENTS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SETTINGS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SUBSCRIPTIONS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_TACTICAL" | "ACCOUNT_PERMISSION_DOMAIN_NAME_TAGS" | "%future added value";
export type AccountPermissionFeatureName = "ACCOUNT_PERMISSION_FEATURE_NAME_ANALYTICS_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_API_APPLICATIONS" | "ACCOUNT_PERMISSION_FEATURE_NAME_BRAND_KIT_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_CAMPAIGNS" | "ACCOUNT_PERMISSION_FEATURE_NAME_CONCIERGE" | "ACCOUNT_PERMISSION_FEATURE_NAME_CONVERSATIONS" | "ACCOUNT_PERMISSION_FEATURE_NAME_CUSTOM_ATTRIBUTES" | "ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_SETTINGS" | "ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_TEMPLATE_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_JOURNEYS" | "ACCOUNT_PERMISSION_FEATURE_NAME_KEYWORDS" | "ACCOUNT_PERMISSION_FEATURE_NAME_MESSAGE_SERVICE_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_OFFER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_POLLS" | "ACCOUNT_PERMISSION_FEATURE_NAME_SEGMENT_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SIGNUP_UNITS" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_BULK_OPT_OUT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_UPLOAD" | "ACCOUNT_PERMISSION_FEATURE_NAME_TAG_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_USER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_VENDOR_INTEGRATIONS" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type fetchAccountPermissions_AcoreUtils_Query$variables = {
  companyId: string;
};
export type fetchAccountPermissions_AcoreUtils_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fetchAccountPermissions_AcoreUtils_fragment_query">;
};
export type fetchAccountPermissions_AcoreUtils_Query$rawResponse = {
  readonly viewer: {
    readonly account: {
      readonly companyAccountPermissions: {
        readonly payload: {
          readonly __typename: "AccountPermissions";
          readonly __isNode: "AccountPermissions";
          readonly featurePermissions: ReadonlyArray<{
            readonly feature: {
              readonly accountPermissionFeatureName: AccountPermissionFeatureName;
              readonly domain: {
                readonly id: string;
                readonly name: AccountPermissionDomainName;
              } | null;
              readonly id: string;
            } | null;
            readonly featureOperations: {
              readonly create: boolean;
              readonly delete: boolean;
              readonly read: boolean;
              readonly update: boolean;
            };
            readonly id: string;
          }>;
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: "DefaultStandardError";
          readonly __isNode: "DefaultStandardError";
          readonly id: string;
          readonly message: string;
          readonly status: StandardErrorStatus;
          readonly title: string;
          readonly traceId: string;
        } | {
          readonly __typename: string;
          readonly __isNode: string;
          readonly id: string;
        } | null;
      } | null;
      readonly id: string;
    } | null;
  } | null;
};
export type fetchAccountPermissions_AcoreUtils_Query = {
  rawResponse: fetchAccountPermissions_AcoreUtils_Query$rawResponse;
  response: fetchAccountPermissions_AcoreUtils_Query$data;
  variables: fetchAccountPermissions_AcoreUtils_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "companyId",
    "variableName": "companyId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "FeaturePermissions",
  "kind": "LinkedField",
  "name": "featurePermissions",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureOperations",
      "kind": "LinkedField",
      "name": "featureOperations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "read",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "delete",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "create",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "update",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DomainFeature",
      "kind": "LinkedField",
      "name": "feature",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountPermissionFeatureName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountPermissionsDomain",
          "kind": "LinkedField",
          "name": "domain",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "traceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchAccountPermissions_AcoreUtils_Query",
    "selections": [
      {
        "kind": "InlineDataFragmentSpread",
        "name": "fetchAccountPermissions_AcoreUtils_fragment_query",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "GetAccountPermissionsByCompanyAndAccountPayload",
                    "kind": "LinkedField",
                    "name": "companyAccountPermissions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "payload",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v1/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "type": "AccountPermissions",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "type": "DefaultStandardError",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "args": (v2/*: any*/),
        "argumentDefinitions": (v0/*: any*/)
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchAccountPermissions_AcoreUtils_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "GetAccountPermissionsByCompanyAndAccountPayload",
                "kind": "LinkedField",
                "name": "companyAccountPermissions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "payload",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "type": "AccountPermissions",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "type": "DefaultStandardError",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b1a86868a1254eade34a2a33f8f70f8",
    "id": null,
    "metadata": {},
    "name": "fetchAccountPermissions_AcoreUtils_Query",
    "operationKind": "query",
    "text": "query fetchAccountPermissions_AcoreUtils_Query(\n  $companyId: ID!\n) {\n  ...fetchAccountPermissions_AcoreUtils_fragment_query_MWiJC\n}\n\nfragment fetchAccountPermissions_AcoreUtils_fragment_query_MWiJC on Query {\n  viewer {\n    account {\n      id\n      companyAccountPermissions(companyId: $companyId) {\n        payload {\n          __typename\n          ... on AccountPermissions {\n            __typename\n            id\n            name\n            featurePermissions {\n              id\n              featureOperations {\n                read\n                delete\n                create\n                update\n              }\n              feature {\n                id\n                accountPermissionFeatureName\n                domain {\n                  id\n                  name\n                }\n              }\n            }\n          }\n          ... on DefaultStandardError {\n            __typename\n            message\n            status\n            title\n            traceId\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "645e62c77dce135d5d7b85f94f73ef1e";

export default node;
