/**
 * @generated SignedSource<<f6eb4e5e41307fec11bc6a218c28465c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type AccountPermissionDomainName = "ACCOUNT_PERMISSION_DOMAIN_NAME_ANALYTICS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_APPLICATIONS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_BRAND_KIT" | "ACCOUNT_PERMISSION_DOMAIN_NAME_EMAIL" | "ACCOUNT_PERMISSION_DOMAIN_NAME_GROWTH" | "ACCOUNT_PERMISSION_DOMAIN_NAME_MESSAGING" | "ACCOUNT_PERMISSION_DOMAIN_NAME_OFFERS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SEGMENTS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SETTINGS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_SUBSCRIPTIONS" | "ACCOUNT_PERMISSION_DOMAIN_NAME_TACTICAL" | "ACCOUNT_PERMISSION_DOMAIN_NAME_TAGS" | "%future added value";
export type AccountPermissionFeatureName = "ACCOUNT_PERMISSION_FEATURE_NAME_ANALYTICS_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_API_APPLICATIONS" | "ACCOUNT_PERMISSION_FEATURE_NAME_BRAND_KIT_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_CAMPAIGNS" | "ACCOUNT_PERMISSION_FEATURE_NAME_CONCIERGE" | "ACCOUNT_PERMISSION_FEATURE_NAME_CONVERSATIONS" | "ACCOUNT_PERMISSION_FEATURE_NAME_CUSTOM_ATTRIBUTES" | "ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_SETTINGS" | "ACCOUNT_PERMISSION_FEATURE_NAME_EMAIL_TEMPLATE_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_JOURNEYS" | "ACCOUNT_PERMISSION_FEATURE_NAME_KEYWORDS" | "ACCOUNT_PERMISSION_FEATURE_NAME_MESSAGE_SERVICE_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_OFFER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_POLLS" | "ACCOUNT_PERMISSION_FEATURE_NAME_SEGMENT_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SIGNUP_UNITS" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_BULK_OPT_OUT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_SUBSCRIBER_UPLOAD" | "ACCOUNT_PERMISSION_FEATURE_NAME_TAG_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_USER_MANAGEMENT" | "ACCOUNT_PERMISSION_FEATURE_NAME_VENDOR_INTEGRATIONS" | "%future added value";
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type fetchAccountPermissions_AcoreUtils_fragment_query$data = {
  readonly viewer: {
    readonly account: {
      readonly companyAccountPermissions: {
        readonly payload: {
          readonly __typename: "AccountPermissions";
          readonly featurePermissions: ReadonlyArray<{
            readonly feature: {
              readonly accountPermissionFeatureName: AccountPermissionFeatureName;
              readonly domain: {
                readonly id: string;
                readonly name: AccountPermissionDomainName;
              } | null;
              readonly id: string;
            } | null;
            readonly featureOperations: {
              readonly create: boolean;
              readonly delete: boolean;
              readonly read: boolean;
              readonly update: boolean;
            };
            readonly id: string;
          }>;
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: "DefaultStandardError";
          readonly message: string;
          readonly status: StandardErrorStatus;
          readonly title: string;
          readonly traceId: string;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
      } | null;
      readonly id: string;
    } | null;
  } | null;
  readonly " $fragmentType": "fetchAccountPermissions_AcoreUtils_fragment_query";
};
export type fetchAccountPermissions_AcoreUtils_fragment_query$key = {
  readonly " $data"?: fetchAccountPermissions_AcoreUtils_fragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"fetchAccountPermissions_AcoreUtils_fragment_query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "fetchAccountPermissions_AcoreUtils_fragment_query"
};

(node as any).hash = "56820c857f18a40698da2f796e787291";

export default node;
